const shuffle = function (array) {
  let i = array.length;
  while(i > 0) {
    let j = Math.floor(Math.random() * i);
    let t = array[--i];
    array[i] = array[j];
    array[j] = t;
  }
  return array;
};
